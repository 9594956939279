import React, { useEffect, useCallback, useState, useRef } from 'react';

import * as yup from 'yup';

import {
  Container,
  Action,
  // Dialog,
  Editor,
  Form,
  FormInput,
  Checkbox,
} from 'components';

import {
  useLocalStorage,
  useLoading,
  useCredentials,
  useModal,
  useToast,
} from 'hooks';
import { useLocation } from 'react-router-dom';
import { MessageProps } from 'interfaces';

import api from 'services';
import { FormHandles } from '@unform/core';

import { compareDates, formatDateTimeForDB } from 'utils/formatDate';
import getValidationErrors from 'utils/getValidationErrors';
import { FaCheckSquare } from 'react-icons/fa';
import { InputContainer, TableWrapper } from './styles';

function Mensageria() {
  const insertForm = useRef<FormHandles>(null);
  const updateForm = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { toggleLoading } = useLoading();
  const { toggleModal, modalConfig } = useModal();
  const { handlePermission, errorHandling } = useCredentials();
  const { state } = useLocation();
  const { setLocalStorage, getLocalStorage, deleteLocalStorage } =
    useLocalStorage();
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [diagContent, setDiagContent] = useState(null);
  const [diagType, setDiagType] = useState<string>(null);
  const [editorError, setEditorError] = useState(false);
  const [displayEditor, setDisplayEditor] = useState(true);
  const [currentDateTime, setCurrentDateTime] = useState(new Date().toISOString().slice(0.16));

  const handleGetList = useCallback(async () => {
    try {
      setMessages([]);
      const response = await api.get(
        '/system/mensagem_list.php?screen=management',
      );
      setMessages(response.data);
    } catch (err) {
      errorHandling(err);
    } finally {
      toggleLoading();
    }
  }, [errorHandling]);

  useEffect(() => {
    if (state) {
      handlePermission(state);
    }
    toggleLoading();
    handleGetList();
  }, [state, toggleLoading]);

  const handleClearContent = useCallback(() => {
    setDiagType(null);
    setDiagContent(null);
  }, []);

  // const handleShowDialog = useCallback(
  //   (ev) => {
  //     console.clear();
  //     setDiagType('delete');
  //     setDiagContent(ev.detail.content);
  //     toggleModal(ev);
  //   },
  //   [setDiagType, setDiagContent, toggleModal],
  // );

  const handleDelete = useCallback(
    async (ev: any) => {
      const locallyStored = getLocalStorage('message2Delete') as any;
      const messageId = locallyStored.id;

      toggleLoading();
      try {
        // const { messageId } = ev.detail;
        const send = new FormData();
        send.append('data', JSON.stringify({ messageId }));

        await api.post('/system/mensagem_delete.php', send, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setMessages((prevState) =>
          prevState.filter((item) => item.id !== messageId),
        );
        setDiagType(null);
        addToast({
          type: 'success',
          title: '',
          description: 'Mensagem removida.',
        });

        deleteLocalStorage('message2Delete');
      } catch (err) {
        console.error(err);
      } finally {
        toggleModal();
        toggleLoading();
      }
    },
    [toggleModal, setMessages, addToast, toggleModal, toggleLoading],
  );

  const handlePreInsert = useCallback((ev: any | null) => {
    const customEv = new CustomEvent('displayEditorContent');
    window.dispatchEvent(customEv);
  }, []);

  const retrieveInsertContent = useCallback((data) => {
    insertForm.current.setFieldValue('editorContent', data);
    insertForm.current.submitForm();
  }, []);

  const handleInsert = useCallback(async () => {
    try {
      insertForm.current.setErrors({});
      setEditorError(false);
      const data = insertForm.current.getData();

      const schema = yup.object().shape({
        from: yup.string().required('Data inválida'),
        due: yup
          .string()
          .test(
            'check-with-dtinc',
            'Data deve ser posterior à data de inclusão',
            (date) => {
              return compareDates(data.from, date);
            },
          )
          .required('Data inválida'),
        editorContent: yup.string().required('empty editor'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      toggleLoading();
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({ ...data, content: data.editorContent }),
      );

      await api.post('/system/mensagem_insert.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Mensagem cadastrada.',
      });

      toggleModal();

      handleGetList();
      setDisplayEditor(false);
      setTimeout(() => {
        setDisplayEditor(true);
        setDiagType(null);
        // insertForm.current.reset();
      }, 10);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (Object.keys(errors).includes('editorContent')) {
          setEditorError(true);
        }
        insertForm.current?.setErrors(errors);
      }
    }
  }, [
    setEditorError,
    toggleLoading,
    addToast,
    toggleModal,
    handleGetList,
    setDisplayEditor,
  ]);

  const handlePreUpdate = useCallback((ev: any | null) => {
    const customEv = new CustomEvent('displayEditorContent');
    window.dispatchEvent(customEv);
  }, []);

  const retrieveUpdateContent = useCallback((data) => {
    updateForm.current.setFieldValue('editorContent', data);
    updateForm.current.submitForm();
  }, []);

  const handleUpdate = useCallback(async () => {
    try {
      updateForm.current.setErrors({});
      setEditorError(false);
      const data = updateForm.current.getData();

      const schema = yup.object().shape({
        from: yup.string().required('Data inválida'),
        due: yup
          .string()
          .test(
            'check-with-dtinc',
            'Data deve ser posterior à data de inclusão',
            (date) => {
              return compareDates(data.from, date);
            },
          )
          .required('Data inválida'),
        editorContent: yup.string().required('empty editor'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      toggleLoading();
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({ ...data, content: data.editorContent }),
      );

      await api.post('/system/mensagem_update.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Sucesso!',
        description: 'Mensagem alterada',
      });

      toggleModal();

      handleGetList();
      setDisplayEditor(false);
      setTimeout(() => {
        setDisplayEditor(true);
      }, 10);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (Object.keys(errors).includes('editorContent')) {
          setEditorError(true);
        }
        updateForm.current?.setErrors(errors);
      }
    }
  }, [
    setEditorError,
    toggleLoading,
    addToast,
    toggleModal,
    handleGetList,
    setDisplayEditor,
  ]);

  const handleCheckboxToggle = useCallback(async (ev) => {
    const { id } = ev.target.dataset;

    try {
      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          messageId: id,
        }),
      );

      await api.post('/system/mensagem_toggle_active.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (err) {
      console.log(err);
    } finally {
      // toggleLoading();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('insertConfirmAction', handlePreInsert);
    document.addEventListener('updateConfirmAction', handlePreUpdate);
    document.addEventListener('deleteConfirmAction', handleDelete);
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date().toISOString().slice(0, 16));
    }, 1000);

    return () => {
      deleteLocalStorage('message2Delete');
      document.removeEventListener('insertConfirmAction', handlePreInsert);
      document.removeEventListener('updateConfirmAction', handlePreUpdate);
      document.removeEventListener('deleteConfirmAction', handleDelete);
    };
  }, []);

  const handleActivateInsertDialog = useCallback(() => {
    // console.clear();
    // setDiagType('insert');
    // toggleModal();
    // setDiagContent({});
    // setTimeout(() => {
    //   setEditorError(false);
    //   insertForm.current.setErrors({});
    //   insertForm.current.reset();
    // }, 50);

    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content: (
          <Form
            submitHandler={handleInsert}
            validationShape={null}
            preventButton
            customFormRef={insertForm}
          >
            <InputContainer>
              <span>
                <p>Data da mensagem</p>
                <FormInput
                  name="from"
                  type="datetime-local"
                  value={currentDateTime}
                  containerstyle={{ width: '200px' }}
                />
              </span>
              <span>
                <p>Validade da Mensagem</p>
                <FormInput
                  name="due"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
            </InputContainer>
            <FormInput name="editorContent" ishidden />
            {displayEditor ? (
              <Editor
                enableSaveButton={false}
                getEditorContent={retrieveInsertContent}
                hasError={editorError}
              />
            ) : null}
          </Form>
        ),
        dialogType: 'insert',
        confirmActionReference: 'insertConfirmAction',
        cancelActionReference: '',
      },
    });

    modalConfig(customEvent);
  }, []);

  const handleActivateUpdateDialog = useCallback((ev: any) => {
    const { from, due, id, content } = ev.detail;

    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content: (
          <Form
            submitHandler={handleUpdate}
            validationShape={null}
            preventButton
            customFormRef={updateForm}
            initialData={{ from, due, id, content, action: 'update' }}
          >
            <InputContainer>
              <span>
                <p>Data da mensagem</p>
                <FormInput
                  name="from"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
              <span>
                <p>Validade da Mensagem</p>
                <FormInput
                  name="due"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
            </InputContainer>
            <FormInput name="id" ishidden />
            <FormInput name="action" ishidden />
            <FormInput name="editorContent" ishidden />
            {displayEditor ? (
              <Editor
                enableSaveButton={false}
                getEditorContent={retrieveUpdateContent}
                hasError={editorError}
                content={content}
              />
            ) : null}
          </Form>
        ),
        dialogType: 'update',
        confirmActionReference: 'updateConfirmAction',
      },
    });
    modalConfig(customEvent);
  }, []);

  const handleActivateDeleteDialog = useCallback((ev) => {
    console.clear();
    const { messageId, content } = ev.detail;
    setLocalStorage('message2Delete', { id: messageId });

    const customEvent = new CustomEvent('modalConfig', {
      detail: {
        content,
        dialogType: 'delete',
        confirmActionReference: 'deleteConfirmAction',
      },
    });

    modalConfig(customEvent);
  }, []);

  return (
    <Container
      showIncludeButton={state.write}
      buttonAction={handleActivateInsertDialog}
      screenBanner={<div>Relação das Mensagens Cadastradas</div>}
    >
      {messages.length > 0 ? (
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>Início</th>
                <th>Validade</th>
                <th>Mensagem</th>
                <th>Status</th>
                {state.write && (
                  <>
                    <th>Editar</th>
                    <th>Excluir</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {messages.map((item) => (
                <tr key={item.id}>
                  <td>{item.fromFormat}</td>
                  <td>{item.dueFormat}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.message,
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox
                      checkboxId={item.id}
                      defaultChecked={item.status}
                      ontoggle={handleCheckboxToggle}
                      isdisabled={!state.write}
                    />
                  </td>
                  {state.write && (
                    <>
                      <td>
                        <Action
                          type="edit"
                          button={{
                            action: handleActivateUpdateDialog,
                            dataset: {
                              from: item.from,
                              due: item.due,
                              content: item.message,
                              id: item.id,
                            },
                          }}
                        />
                      </td>
                      <td>
                        <Action
                          type="delete"
                          button={{
                            action: handleActivateDeleteDialog,
                            dataset: {
                              messageId: item.id,
                              content: (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.message,
                                  }}
                                />
                              ),
                            },
                          }}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      ) : null}

      {/* {diagType === 'insert' ? (
        <Dialog
          confirmAction={handlePreInsert}
          cancelAction={handleClearContent}
          dialogtype="insert"
        >
          <Form
            submitHandler={handleInsert}
            validationShape={null}
            preventButton
            customFormRef={insertForm}
          >
            <InputContainer>
              <span>
                <p>Data da mensagem</p>
                <FormInput
                  name="from"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
              <span>
                <p>Validade da Mensagem</p>
                <FormInput
                  name="due"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
            </InputContainer>
            <FormInput name="editorContent" ishidden />
            {displayEditor ? (
              <Editor
                enableSaveButton={false}
                getEditorContent={retrieveInsertContent}
                hasError={editorError}
              />
            ) : null}
          </Form>
        </Dialog>
      ) : null}
      {diagType === 'update' ? (
        <Dialog
          confirmAction={handlePreUpdate}
          cancelAction={handleClearContent}
          dialogtype="update"
        >
          <Form
            submitHandler={handleUpdate}
            validationShape={null}
            preventButton
            customFormRef={updateForm}
            initialData={{ ...diagContent }}
          >
            <InputContainer>
              <span>
                <p>Data da mensagem</p>
                <FormInput
                  name="from"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
              <span>
                <p>Validade da Mensagem</p>
                <FormInput
                  name="due"
                  type="datetime-local"
                  containerstyle={{ width: '200px' }}
                />
              </span>
            </InputContainer>
            <FormInput name="id" ishidden />
            <FormInput name="action" ishidden />
            <FormInput name="editorContent" ishidden />
            {displayEditor ? (
              <Editor
                enableSaveButton={false}
                getEditorContent={retrieveUpdateContent}
                hasError={editorError}
                content={diagContent?.content}
              />
            ) : null}
          </Form>
        </Dialog>
      ) : null}
      {diagType === 'delete' ? (
        <Dialog
          confirmAction={handleDelete}
          cancelAction={handleClearContent}
          dialogtype="delete"
        >
          {diagContent}
        </Dialog>
      ) : null} */}
    </Container>
  );
}

export default Mensageria;
