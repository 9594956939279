import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormInput, FormSelect, FormRadio, FormTextarea } from 'components';

import api from 'services';
import { ComboProps } from 'interfaces';
import ComponentWrapper from '../wrapper';

import { lsKey } from '../../assisted';

interface EducationProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorEducation({ mode, data }: EducationProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [educationLevels, setEducationLevels] = useState<ComboProps[]>([]);
  const [educationStatus, setEducationStatus] = useState<ComboProps[]>([]);
  const [educationPeriod, setEducationPeriod] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/assistida_escolaridade.php');
      setEducationLevels(response.data.educationLevel);
      setEducationStatus(response.data.educationStatus);
      setEducationPeriod(response.data.educationPeriod);
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }
  }, [handleGetCombos]);

  return (
    <ComponentWrapper sectionName="Escolaridade">
      <div>
        <FormSelect
          name="education"
          label="Nível Escolaridade"
          content={educationLevels}
          initial={prefixed.education || null}
          disabled={mode === 'show'}
        />
        <FormRadio
          name="education_status"
          label="Situação"
          options={educationStatus}
          selected={prefixed.education_status || null}
          containerstyle={{ marginTop: '12px' }}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormInput
          name="last_school"
          placeholder="Ultima (ou atual) instituição de ensino"
          label="Nome da Última (ou Atual) Instituição de Ensino"
          containerstyle={{
            width: '750px',
          }}
          defaultValue={prefixed.last_school || null}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormRadio
          name="period"
          label="Periodo das Aulas Escolares"
          options={educationPeriod}
          selected={prefixed.period || null}
          containerstyle={{ marginTop: '-23px' }}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormTextarea
          altHeight={75}
          label="Curso extra"
          name="course_info"
          placeholder="Informações do curso extra"
          defaultValue={prefixed.course_info || null}
          containerstyle={{ marginTop: '25px' }}
          disabled={mode === 'show'}
        />
      </div>
      <div>
        <FormRadio
          name="course"
          label="Situação do Curso Extra"
          options={educationStatus}
          selected={prefixed.course || null}
          containerstyle={{ marginTop: '-35px', width: '340px' }}
          disabled={mode === 'show'}
        />
      </div>
    </ComponentWrapper>
  );
}

ColaboradorEducation.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorEducation;
