import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { AppProps, NavigationModules } from 'interfaces';
import { useEncoder, useToast, useChanges, useLoading } from 'hooks';
import api from 'services';
import localStorageItems from './localStorageItems';

export interface User {
  id: string;
  name: string;
  firstname: string;
  surname: string;
  avatar: string;
  adm: boolean;
  passchange: boolean;
  function: string;
  area: string;
  navigation: NavigationModules[];
  mail: string;
  cpf: string;
}

interface SignInCredentials {
  cpf: string;
  pass: string;
  who?: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): void;
  signOut(): void;
  updateUser(user: User): void;
  updateADM(user: User, token: string): void;
}

interface AuthState {
  token: string;
  user: User;
}

interface StateProps {
  from: {
    pathname: string;
  };
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
function AuthProvider({ children }: AppProps) {
  const navigate = useNavigate();
  const { et64, df64 } = useEncoder();
  const { getInfo } = useChanges();
  const { errorToast, addToast } = useToast();
  const { toggleLoading } = useLoading();
  const state = useLocation().state as StateProps;

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);
    const user = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: df64(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ cpf, pass, who }) => {
      const u = cpf;
      const p = pass;
      let mode = 'employee';
      if (who) {
        mode = 'adm';
      }
      // const mode = u.match(/[A-Z]/i) ? 'adm' : 'employee';

      const send = et64({
        u,
        p,
        mode,
      });

      toggleLoading();

      try {
        const response = await api.post('/common/login.php', send);

        const { user, token, status, message } = df64(response.data);

        if (status) {
          addToast({
            type: 'error',
            title: 'Erro na requisição',
            description: message,
          });

          return;
        }

        if (user && token) {
          localStorage.setItem(`${process.env.REACT_APP_TOKEN}`, token);
          localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));

          api.defaults.headers.authorization = `Bearer ${token}`;

          setData({ token, user });

          getInfo();
          if (user.passchange) {
            navigate('/first-access', { replace: true });
            return;
          }

          if (state) {
            navigate(state.from.pathname, { replace: true });
            return;
          }
          navigate('/home', { replace: true });
        }
      } catch (err) {
        errorToast('err01');
      } finally {
        toggleLoading();
      }
    },
    [addToast, state, navigate],
  );

  const signOut = useCallback(() => {
    const isAdm = data.user.adm;
    localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);
    localStorage.removeItem(`${process.env.REACT_APP_USER}`);
    localStorageItems.forEach((element) => {
      localStorage.removeItem(element);
    });

    api.defaults.headers.authorization = '';

    setData({} as AuthState);

    navigate(`/${isAdm ? 'adm' : ''}signin`, { replace: true });
  }, [navigate]);

  const updateADM = useCallback(
    (user: User, token: string) => {
      localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));
      localStorage.setItem(`${process.env.REACT_APP_TOKEN}`, token);
      api.defaults.headers.authorization = `Bearer ${token}`;
      setData({
        token,
        user,
      });
      getInfo();
    },
    [setData],
  );

  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem(`${process.env.REACT_APP_USER}`, et64(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        updateADM,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
