import styled from 'styled-components';

export const SearchButton = styled.button`
  --mainColor: #332e2e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 7px;
  width: 30px;
  height: 30px;
  border: 2px solid #efefef;
  border-radius: 3px;
  background: #fff;
  transition: all 0.35s ease;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

  svg {
    transition: all 0.35s ease;
    color: var(--mainColor);
  }

  &:hover {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    background: var(--mainColor);
    border-color: var(--mainColor);
    svg {
      color: #fff;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 7px 0;
  }
`;

export const CloseButton = styled.button`
  padding: 3px 7px;
  width: 150px;
  margin: 0 5px 5px auto;
  border: 2px solid black;
  background-color: #8B0000;
  border: 2px #B22222;
  border-radius: 5px;
  color: #efefef;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    background-color: #A52A2A;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 5px 0;
  }
`;
