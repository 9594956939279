import styled from 'styled-components';

export const FilterWrapper = styled.div`
  --borderColor: #0054a6;
  --fontColor: #004080;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  margin: 0 auto;
  width: 100%;

  input {
    position: relative;
    border-radius: 3px;
    padding: 3px 7px;
    background: #f6f6f6;
    width: 175px;
    margin: 0;
    color: var(--fontColor);
    border: 2px solid var(--borderColor);
    font-size: 14px;

    &::placeholder {
      font-style: italic;
      color: #bfbfbf;
    }
  }

  input[type='search']::-webkit-search-decoration:hover,
  input[type='search']::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }

  select {
    cursor: pointer;
    width: 175px;
    border: 2px solid var(--borderColor);
    border-radius: 3px;

    color: var(--fontColor);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    input,
    select {
      width: 100%;
    }

    margin-bottom: 15px;
  }
`;

export const Wrapper = styled.section`
  width: 90vw;
  padding: 10px;
  margin: 25px auto;
  display: grid;
  grid: auto / 1fr 1fr 1fr 1fr 1fr;

  column-gap: 40px;
  row-gap: 30px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Child = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 5px 7px;
  border: 3px solid #d6d6d6;
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.5);
`;

export const ChildAvatar = styled.span`
  --size: 65px;
  --position: -25px;

  /* position: absolute; */
  top: -10px;
  left: -18px;

  background: #fff;
  border: 2.5px solid #666666;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  img {
    width: 100%;
    object-fit: contain;
  }

  box-shadow: 1px 2.5px 5px 1.5px rgba(0, 0, 0, 0.5);
`;

export const ChildInfo = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p,
  strong {
    font-size: 14px;
  }

  p:nth-child(3) {
    font-style: italic;
  }

  margin: 0 0 35px 0;
`;

export const FonteText = styled.div`
  color: #ffffff;
  font-weight: bold;
`;
