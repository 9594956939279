import React, { useCallback, useEffect, useState } from 'react';
import { AppProps } from 'interfaces';

import { useAuth, useModal } from 'hooks';
import { createPortal } from 'react-dom';

import { useSpring } from '@react-spring/web';

import {
  ModalContainer,
  ModalBodyWrapper,
  ModalHeader,
  ModalContent,
  ModalContentChildren,
  ModalFooter,
  CancelButton,
  ConfirmButton,
} from './styles';

const modalRoot = document.getElementById('modal-root');

function Modal() {
  const { user } = useAuth();
  const {
    active,
    toggleModal,
    modalContent,
    confirmReference,
    cancelReference,
    dialogType,
    eventDetails,
  } = useModal();

  const styledModal = useSpring({
    opacity: active ? 1 : 0,
    // zIndex: isLoading ? '10004' : '-1',
  });

  const handleConfirmAction = useCallback(() => {
    const customEvent = new CustomEvent(confirmReference, {
      detail: { ...eventDetails },
    });
    document.dispatchEvent(customEvent);
  }, [confirmReference]);

  const handleCancelAction = useCallback(() => {
    toggleModal();
  }, []);

  return createPortal(
    <ModalContainer
      id="modal-container"
      style={{ ...styledModal, pointerEvents: active ? 'all' : 'none' }}
    >
      <ModalBodyWrapper dialogtype={dialogType}>
        <ModalHeader>
          {dialogType === 'insert'
            ? 'Novo'
            : dialogType === 'update'
            ? 'Editar'
            : 'Excluir'}
          &nbsp;registro
        </ModalHeader>
        <ModalContent>
          {active ? (
            <>
              {dialogType === 'delete' ? (
                <p style={{ margin: '0 0 20px 0' }}>
                  Você está prestes a excluir&nbsp;
                  <strong style={{ color: '#c53030' }}>permanentemente</strong>
                  &nbsp;o registro:
                </p>
              ) : null}
              <ModalContentChildren>{modalContent}</ModalContentChildren>
            </>
          ) : null}
          {/* {dialogType === 'delete' ? (
            <p style={{ margin: '0 0 20px 0' }}>
              Você está prestes a excluir&nbsp;
              <strong style={{ color: '#c53030' }}>permanentemente</strong>
              &nbsp;o registro:
            </p>
          ) : null}
          <ModalContentChildren>{modalContent}</ModalContentChildren> */}
        </ModalContent>
        <ModalFooter>
          <CancelButton type="button" onClick={handleCancelAction}>
            Fechar
          </CancelButton>

          <ConfirmButton
            type="button"
            dialogtype={dialogType}
            onClick={handleConfirmAction}
          >
            {dialogType === 'delete' ? 'Excluir' : 'Confirmar'}
          </ConfirmButton>
        </ModalFooter>
      </ModalBodyWrapper>
    </ModalContainer>,
    modalRoot,
  );
}

export default Modal;
