import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { useEncoder } from 'hooks';

import api from 'services';
import { handleTimeZone } from 'utils/formatDate';
import { AppProps } from 'interfaces';

interface ChangeContextData {
  getInfo(): void;
  closed: boolean;
  start: string;
  end: string;
  currDate: {
    day: number;
    month: number;
    year: number;
    full: Date;
  };
}

export interface ChangeState {
  currDate: {
    day: number;
    month: number;
    year: number;
    full: Date;
  };
  closed: boolean;
  start: string;
  end: string;
}

const ChangeContext = createContext<ChangeContextData>({} as ChangeContextData);

function ChangeProvider({ children }: AppProps) {
  const { df64 } = useEncoder();
  const [data, setData] = useState<ChangeState>({} as ChangeState);

  const getInfo = useCallback(async () => {
    // const response = await api.get<ChangeState>('/common/changes.php');
    try {
      const response = await api.get('/common/changes.php');

      const { currDate }: ChangeState = df64(response.data);
      const { full } = currDate;

      setData((state) => ({
        ...state,
        currDate: {
          ...currDate,
          full: handleTimeZone(full),
        },
      }));
    } catch (err) {
      console.log(err.response);
    }
  }, []);

  const getClosed = useCallback(async () => {
    const response = await api.get('/common/closed.php');

    const { closed, start, end } = df64(response.data);

    setData((state) => ({
      ...state,
      closed,
      start,
      end,
    }));
  }, []);

  useEffect(() => {
    getInfo();
    getClosed();
  }, [getClosed, getInfo]);

  return (
    <ChangeContext.Provider
      value={{
        getInfo,
        closed: data.closed,
        start: data.start,
        end: data.end,
        currDate: data.currDate,
      }}
    >
      {children}
    </ChangeContext.Provider>
  );
}

function useChanges(): ChangeContextData {
  const context = useContext(ChangeContext);

  if (!context) {
    throw new Error('useChanges must be used within an ChangeProvider');
  }

  return context;
}

export { useChanges, ChangeProvider };
