import Assistida from 'pages/social/Assistida/main';
import AssistidaInsert from 'pages/social/Assistida/insert';

const socialRoutes = [
  { path: '/assistidas', component: Assistida },
  { path: '/assistidas/new', component: AssistidaInsert },
].map((item) => {
  return { ...item, path: `/social${item.path}` };
});
export default socialRoutes;
