import styled from 'styled-components';

export const ComponentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 675px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    /* width: 10px; */
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    /* border-radius: 5px 0px 0px 5px; */
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #464646;
    /* border-radius: 5px 0px 0px 5px; */
    transition: background-color 0.25s;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #565656;
  }

  > div {
    display: flex;
    flex-direction: row;

    column-gap: 7px;
    padding: 5px 10px;
  }

  fieldset {
    display: flex;
    flex-direction: column;

    padding: 5px 10px;
    border: 2px solid #a6a6a6;
    border-radius: 5px;
    margin: 0px 5px;
    > legend {
      color: #464646;
      font-weight: bold;
      font-style: italic;
    }
    > div {
      display: flex;
      flex-direction: row;
      column-gap: 7px;
    }
  }
`;
