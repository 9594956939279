import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

import { lighten, shade } from 'polished';

interface DialogProps {
  dialogtype: 'info' | 'insert' | 'update' | 'delete';
}

const palette = {
  update: {
    maincolor: '#007acc',
    color: '#efefef',
  },
  info: {
    maincolor: '#786d6d',
    color: '#efefef',
  },
  delete: {
    maincolor: '#c53030',
    color: '#efefef',
  },
  insert: {
    maincolor: '#2ba32b',
    color: '#efefef',
  },
};

export const ModalContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10003;
  background: rgba(0, 0, 0, 0.35);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  /* transform: translate(-50%, -50%); */
`;

export const ModalBodyWrapper = styled(animated.div)<DialogProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  min-width: 600px;
  background-color: #fff;
  border: 3px solid #efefef;
  border-radius: 5px;
  overflow-x: auto;

  border-color: ${(props) => palette[props.dialogtype].maincolor};

  > div {
    &:nth-child(1) {
      background-color: ${(props) => palette[props.dialogtype].maincolor};
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  height: 50px;
`;

export const ModalContent = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3px 10px;
  background-color: #fff;
`;

export const ModalContentChildren = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ModalFooter = styled.div`
  position: relative;
  display: flex;
  background-color: #fff;

  height: 60px;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  column-gap: 15px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border: 1px solid rgb(189, 189, 189, 0.75);
    background: transparent;
    width: 95%;
    transform: translateX(-50%);
  }
  /* border-top: 2px solid #464646; */
`;

const ButtonAction = styled.button`
  border: 0;
  outline: 0;
  padding: 3px 7px;
  border: 1px solid #fff;
  border-radius: 5px;

  transition: all 0.35s;
`;

export const ConfirmButton = styled(ButtonAction)<DialogProps>`
  background-color: #1b5e20;
  border-color: #1b5e20;
  color: #fff;

  ${(props) =>
    props.dialogtype === 'delete' &&
    css`
      background-color: ${palette[props.dialogtype].maincolor};
      border-color: ${palette[props.dialogtype].maincolor};
    `}

  &:hover {
    background-color: ${lighten(0.2, '#1b5e20')};
    border-color: ${lighten(0.2, '#1b5e20')};

    ${(props) =>
      props.dialogtype === 'delete' &&
      css`
        background-color: ${lighten(
          0.05,
          `${palette[props.dialogtype].maincolor}`,
        )};
        border-color: ${lighten(
          0.05,
          `${palette[props.dialogtype].maincolor}`,
        )};
      `}
  }
`;

export const CancelButton = styled(ButtonAction)`
  background-color: #d6d6d6;
  border-color: #464646;
  color: #464646;

  &:hover {
    background-color: #fff;
  }
`;
