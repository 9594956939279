import React, { createContext, useCallback, useContext } from 'react';

import api from 'services';

import { AppProps } from 'interfaces';

interface EncoderContextData {
  et64(toencode: any): string;
  df64(encoded: string | undefined): any;
}

const EncoderContext = createContext<EncoderContextData>(
  {} as EncoderContextData,
);

function EncoderProvider({ children }: AppProps) {
  const et64 = useCallback((toencode) => {
    return btoa(encodeURIComponent(JSON.stringify(toencode)));
  }, []);

  const df64 = useCallback((encoded): any => {
    if (encoded === undefined) {
      return '';
    }

    if (['(', '[', '{'].indexOf(encoded.substr(0, 1)) > -1) {
      return JSON.parse(encoded);
    }
    const hardCoded = encoded;
    const base64Decoded = atob(hardCoded);
    const urlDecoded = decodeURIComponent(base64Decoded);
    const parsed = JSON.parse(urlDecoded);
    return parsed;
  }, []);

  return (
    <EncoderContext.Provider value={{ et64, df64 }}>
      {children}
    </EncoderContext.Provider>
  );
}

function useEncoder(): EncoderContextData {
  const context = useContext(EncoderContext);

  if (!context) {
    throw new Error('useEncoder must be used within an EncoderProvider');
  }

  return context;
}

export { useEncoder, EncoderProvider };
