import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useLocalStorage, useLoading, useToast } from 'hooks';
import { useNavigate } from 'react-router-dom';

import { Container } from 'components';

import { Tabs, TabList, TabPanel } from 'react-tabs';
import CustomTab, { CustomTabErrors } from 'components/CustomTab';

import 'styles/tabs.css';
// import 'react-tabs/style/react-tabs.css';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';

import api from 'services';

import { ColaboratorFamilyMembers, lsKey, fields } from '../colaborator';
import * as C from '../components';

function ColaboradorNew() {
  const { setLocalStorage, deleteLocalStorage, getLocalStorage } =
    useLocalStorage();
  const { toggleLoading } = useLoading();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [enter, setEnter] = useState(true);
  const formRef = useRef<FormHandles>(null);

  const [currentTab, setCurrentTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(0);

  const [familyInfo, setFamilyInfo] = useState<ColaboratorFamilyMembers>(() => {
    const locallyStored: any = getLocalStorage(lsKey);

    if (locallyStored) {
      if (
        ['C', 'D', 'E'].indexOf(locallyStored.marital_status) > -1 ||
        locallyStored.children > 0
      ) {
        return {
          maritalStatus: locallyStored.marital_status || null,
          childrenAmount: parseInt(locallyStored.children, 10) || 0,
        } as ColaboratorFamilyMembers;
      }
    }
    return {} as ColaboratorFamilyMembers;
  });

  const [tabsErrors, setTabErrors] = useState<CustomTabErrors>({
    personalData: 0,
    address: 0,
    family: 0,
    education: 0,
    role: 0,
  });

  useEffect(() => {
    if (enter) {
      setEnter(false);
      // deleteLocalStorage(lsKey);
    }
  }, [enter, deleteLocalStorage, setEnter]);

  const handleSubmitUser = useCallback(async (data) => {
    console.clear();
    try {
      const formData = getLocalStorage(lsKey);
      const currentData = { ...formData, ...data };

      Object.keys(currentData).forEach((key) => {
        if (
          key.includes('child_name') ||
          key.includes('child_surname') ||
          key.includes('child_birth') ||
          key.includes('child_gender')
        ) {
          delete currentData[key];
        }
      });

      formRef.current?.setErrors({});

      const schema = yup.object().shape({
        // PERSONAL DATA
        name: yup
          .string()
          .trim()
          .min(2, 'Quantidade mínima de caracteres não atingida (2)')
          .required('Campo obrigatório'),
        surname: yup
          .string()
          .trim()
          .min(2, 'Quantidade mínima de caracteres não atingida (2)'),
        birth: yup.string().required('Campo obrigatório'), // TODO: add validation for 15 years old
        rg: yup
          .string()
          .trim()
          .min(5, 'Quantidade mínima de caracteres não atingida (5)'),
        org: yup
          .string()
          .trim()
          .min(3, 'Quantidade mínima de caracteres não atingida (3)'),
        rg_expedition: yup.string().required('Campo obrigatório'),
        cpf: yup
          .string()
          .trim()
          .min(14, 'Quantidade mínima de caracteres não atingida (14)'),
        gender: yup.string().trim().required('Campo obrigatório'),
        ethnicity: yup.string().trim().required('Campo obrigatório'),
        phone: yup.lazy((val) =>
          val
            ? yup
                .string()
                .min(
                  13,
                  'Quantidade mínima de caracteres não atingida [ (xx) xxxx-xxxx ]',
                )
            : yup.string(),
        ),
        mail: yup.string().email('Formato inválido'),
        whatsapp: yup.lazy((val) =>
          val
            ? yup
                .string()
                .min(
                  13,
                  'Quantidade mínima de caracteres não atingida [ (xx) xxxxx-xxxx ]',
                )
            : yup.string(),
        ),
        mobile: yup.lazy((val) =>
          val
            ? yup
                .string()
                .min(
                  13,
                  'Quantidade mínima de caracteres não atingida [ (xx) xxxxx-xxxx ]',
                )
            : yup.string(),
        ),
        // ADDRESS
        cep: yup.string().required('Campo obrigatório'),
        // address_type: yup.string().trim().required('Campo obrigatório'),
        address: yup
          .string()
          .trim()
          .min(5, 'Quantidade mínima de caracteres não atingida (5)')
          .required('Campo obrigatório'),
        number: yup.string().trim().required('Campo obrigatório'),
        habitation: yup.string().required('Cambo obrigatório'),
        district: yup
          .string()
          .trim()
          .min(3, 'Quantidade mínima de caracteres não atingida (3)')
          .required('Campo obrigatório'),
        city: yup
          .string()
          .trim()
          .min(3, 'Quantidade mínima de caracteres não atingida (3)')
          .required('Campo obrigatório'),
        uf: yup.string().required('Campo obrigatório'),
        // FAMILY
        companion_name: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch
                  .trim()
                  .min(2, 'Quantidade mínima de caracteres não atingida (2)')
                  .required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_surname: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch
                  .trim()
                  .min(2, 'Quantidade mínima de caracteres não atingida (2)')
                  .required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_birth: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch.trim().required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_rg: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch
                  .trim()
                  .min(5, 'Quantidade mínima de caracteres não atingida (5)')
                  .required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_cpf: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch
                  .trim()
                  .min(14, 'Quantidade mínima de caracteres não atingida (14)')
                  .required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_gender: yup
          .string()
          .when('marital_status', ([marital_status], sch) => {
            return ['C', 'D', 'E'].indexOf(marital_status) > -1
              ? sch.trim().required('Campo obrigatório')
              : sch.notRequired();
          }),
        companion_phone: yup.lazy((val) =>
          val
            ? yup
                .string()
                .min(
                  13,
                  'Quantidade mínima de caracteres não atingida [ (xx) xxxx-xxxx ]',
                )
            : yup.string(),
        ),
        companion_mail: yup.string().email('Formato inválido'),
        companion_mobile: yup.lazy((val) =>
          val
            ? yup
                .string()
                .min(
                  13,
                  'Quantidade mínima de caracteres não atingida [ (xx) xxxxx-xxxx ]',
                )
            : yup.string(),
        ),
        // EDUCATION
        education: yup.string().trim().required('Campo obrigatório'),
        education_status: yup.string().when('education', ([education], sch) => {
          return education && education !== '1'
            ? sch.trim().required('Campo obrigatório')
            : sch.notRequired();
        }),
        last_school: yup.string().when('education', ([education], sch) => {
          return education && education !== '1'
            ? sch
                .trim()
                .min(5, 'Quantidade mínima de caracteres não atingida (5)')
                .required('Campo obrigatório')
            : sch.notRequired();
        }),
        period: yup.string().when('education', ([education], sch) => {
          return education && education !== '1'
            ? sch.trim().required('Campo obrigatório')
            : sch.notRequired();
        }),
        course: yup.string().when('course_info', ([course_info], sch) => {
          return course_info
            ? sch.trim().required('Campo obrigatório')
            : sch.notRequired();
        }),
        // ROLE
        cbo: yup.string().required('Campo obrigatório'),
        role: yup
          .string()
          .min(5, 'Quantidade mínima de caracteres não atingida (5)')
          .required('Campo obrigatório'),
        area: yup
          .string()
          .min(5, 'Quantidade mínima de caracteres não atingida (5)')
          .required('Campo obrigatório'),
        activities: yup.string().required('Campo obrigatório'),
        bond: yup.string().required('Campo obrigatório'),
        register_num: yup.string().required('Campo obrigatório'),
        job_start: yup.string().required('Campo obrigatório'),
        person_type: yup.string().required('Campo obrigatório'),
      });

      await schema.validate(currentData, {
        abortEarly: false,
      });

      toggleLoading();

      setTabErrors({
        personalData: 0,
        address: 0,
        family: 0,
        education: 0,
        role: 0,
      });

      const send = new FormData();
      send.append(
        'data',
        JSON.stringify({
          ...currentData,
          children_info: currentData.children_info
            ? JSON.parse(currentData.children_info)
            : [],
        }),
      );

      await api.post('/system/colaborador_actions.php', send, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      addToast({
        type: 'success',
        title: 'Colaborador cadastrado',
      });

      navigate(-1);
    } catch (err) {
      console.log(err);
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        const fieldsErrors = {
          personalData: 0,
          address: 0,
          family: 0,
          education: 0,
          role: 0,
        };

        Object.keys(errors).forEach((item) => {
          if (fields.personal.includes(item)) {
            fieldsErrors.personalData += 1;
          }
          if (fields.address.includes(item)) {
            fieldsErrors.address += 1;
          }
          if (fields.family.includes(item)) {
            fieldsErrors.family += 1;
          }
          if (fields.education.includes(item)) {
            fieldsErrors.education += 1;
          }
          if (fields.role.includes(item)) {
            fieldsErrors.role += 1;
          }
        });

        setTabErrors(fieldsErrors);
        toggleLoading();
      }
    } finally {
      toggleLoading();
    }
  }, []);

  const handleCurrentTab = useCallback((tab, previous) => {
    console.clear();
    const formObject = formRef.current.getData();

    const filledProperties = {};
    Object.keys(formObject).forEach((item) => {
      if (formObject[item] !== null) {
        filledProperties[item] = formObject[item];
      }
    });

    setLocalStorage(lsKey, {
      ...filledProperties,
    });

    setTimeout(() => {
      setCurrentTab(tab);
      setPreviousTab(previous);
    }, 10);
  }, []);

  const handleChanges = useCallback((data) => {
    let status = false;
    if (
      ['C', 'D', 'E'].indexOf(data.marital_status) > -1 ||
      data.children > 0
    ) {
      status = true;
    }
    setFamilyInfo({
      maritalStatus: data.marital_status || null,
      childrenAmount: data.children || 0,
    });
  }, []);

  return (
    <Container screenBanner={<div>Novo Colaborador</div>} hideNavbar>
      <Form
        ref={formRef}
        onSubmit={handleSubmitUser}
        style={{ margin: '0 auto' }}
        placeholder=""
      >
        <Tabs onSelect={handleCurrentTab} selectedIndex={currentTab}>
          <TabList>
            <CustomTab errorAmount={tabsErrors.personalData}>
              Dados Pessoais
            </CustomTab>
            <CustomTab errorAmount={tabsErrors.address}>Endereço</CustomTab>
            <CustomTab errorAmount={tabsErrors.family}>Familiares</CustomTab>
            <CustomTab errorAmount={tabsErrors.education}>
              Escolaridade
            </CustomTab>
            <CustomTab errorAmount={tabsErrors.role}>Função</CustomTab>
          </TabList>

          <TabPanel>
            <C.Personal onDataChange={handleChanges} />
          </TabPanel>
          <TabPanel>
            <C.Address />
          </TabPanel>
          <TabPanel>
            <C.Family familyInfo={familyInfo} />
          </TabPanel>
          <TabPanel>
            <C.Education />
          </TabPanel>
          <TabPanel>
            <C.Role />
          </TabPanel>
        </Tabs>
      </Form>
    </Container>
  );
}

export default ColaboradorNew;
