import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppProps } from 'interfaces';

import Button from 'components/Button';
import { useLoading } from 'hooks';

import { FormHandles, FormHelpers, SubmitHandler } from '@unform/core';

import { useSpring, animated } from '@react-spring/web';

import * as yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { Wrapper } from './styles';

interface FormProps extends AppProps {
  submitButtonLabel?: string;
  submitHandler: SubmitHandler;
  validationShape: object;
  buttonProps?: object;
  preventButton?: boolean;
  customFormRef?: any;
  initialData?: object;
}

function Form({
  submitHandler,
  children,
  submitButtonLabel,
  validationShape,
  buttonProps,
  preventButton,
  customFormRef,
  initialData,
  ...rest
}: FormProps) {
  const { loading } = useLoading();
  const formRef = customFormRef || useRef<FormHandles>(null);
  const [reRender, setReRender] = useState(false);

  const onSubmit: SubmitHandler<any> = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});
      const schema = yup.object().shape({ ...validationShape });
      await schema.validate(data, {
        abortEarly: false,
      });

      submitHandler(data, {} as FormHelpers);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }
      console.log('err');
    } finally {
      // end
    }
  }, []);

  // const onSubmit: SubmitHandler<any> = (data) => {
  // toggleLoading();
  // try {
  //   const schema = yup.object().shape({ ...validationShape });
  // } catch (err) {
  // } finally {
  //   toggleLoading();
  // }
  // submitHandler(data, {} as FormHelpers);
  // };

  useEffect(() => {
    setReRender(true);
    setTimeout(() => {
      setReRender(false);
    }, 10);
  }, [initialData]);

  const styled = useSpring({
    opacity: reRender ? 0 : 1,
  });

  return (
    <Wrapper ref={formRef} onSubmit={onSubmit} initialData={initialData} placeholder={''}>
      {!reRender ? (
        <animated.div
          style={{ ...styled, pointerEvents: reRender ? 'none' : 'all' }}
        >
          {children}
          {preventButton ? null : (
            <Button
              type="submit"
              loading={loading}
              containerStyle={buttonProps}
            >
              {submitButtonLabel}
            </Button>
          )}
        </animated.div>
      ) : null}
    </Wrapper>
  );
}
Form.defaultProps = {
  submitButtonLabel: 'Enviar',
  buttonProps: {},
  preventButton: false,
  initialData: null,
  customFormRef: null,
};
export default Form;
