import styled, { css } from 'styled-components';

export const fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  border: 2px solid #a6a6a6;
  border-radius: 5px;
  margin: 0px 0px;
`;

export const CloseButton = styled.button`
  padding: 3px 7px;
  width: 150px;
  margin: 0 5px 5px auto;
  border: 2px solid black;
  background-color: #8B0000;
  border: 2px #B22222;
  border-radius: 5px;
  color: #efefef;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    background-color: #A52A2A;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 5px 0;
  }
`;
