import styled from 'styled-components';
import { Form } from '@unform/web';

export const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 auto;

  div + div {
    margin: 5px 0;
  }
  button {
    margin: 0 auto;
  }
`;
