import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { AppProps } from 'interfaces';

interface LocalStorageProps {
  [key: string]: string | number | null | object;
}

interface LocalStorageData {
  setLocalStorage(item: string, values: LocalStorageProps): void;
  getLocalStorage(item: string): object;
  deleteLocalStorage(item: string): void;
  deleteLocalStorageItemKey(item: string, values: string[]): void;
}

const LocalStorageContext = createContext<LocalStorageData>(
  {} as LocalStorageData,
);

function LocalStorageProvider({ children }: AppProps) {
  const setLocalStorage = useCallback(
    (item: string, values: LocalStorageProps) => {
      const localstorageitem = localStorage.getItem(item);

      if (localstorageitem) {
        const parsed = JSON.parse(localstorageitem);

        const updatedItem = {
          ...parsed,
          ...values,
        };

        localStorage.setItem(item, JSON.stringify(updatedItem));
      } else {
        localStorage.setItem(item, JSON.stringify(values));
      }
    },
    [],
  );

  const getLocalStorage = useCallback((item: string): object => {
    const localStorageItem = localStorage.getItem(item);

    if (localStorageItem) {
      return JSON.parse(localStorageItem);
    }

    return {};
  }, []);

  const deleteLocalStorage = useCallback((item: string) => {
    localStorage.removeItem(item);
  }, []);

  const deleteLocalStorageItemKey = useCallback(
    (item: string, values: string[]) => {
      const localStorageItem = localStorage.getItem(item);

      if (localStorageItem) {
        const stored = JSON.parse(localStorageItem);
        values.map((ocurr) => {
          delete stored[ocurr];
          return null;
        });
        localStorage.setItem(item, JSON.stringify(stored));
      }
    },
    [],
  );

  return (
    <LocalStorageContext.Provider
      value={{
        setLocalStorage,
        getLocalStorage,
        deleteLocalStorage,
        deleteLocalStorageItemKey,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
}

function useLocalStorage(): LocalStorageData {
  const context = useContext(LocalStorageContext);

  if (!context) {
    throw new Error(
      'useLocalStorage must be used within an LocalStorageProvider',
    );
  }

  return context;
}

export { LocalStorageProvider, useLocalStorage };
