import React from 'react';
import { useAuth } from 'hooks';
import { Link } from 'react-router-dom';
import { HeaderWrapper, UserInfo, UserInfoDiv, Photo } from './styles';

function Header() {
  const { user } = useAuth();
  return (
    <HeaderWrapper>
      <UserInfo>
        <UserInfoDiv>
          <h3>{user.firstname}</h3>
          <p>
            Área:&nbsp;<strong>{user.area}</strong>
          </p>
          <p>
            Função:&nbsp;<strong>{user.function}</strong>
          </p>
        </UserInfoDiv>
        <UserInfoDiv>
          <Photo disabled={false}>
            <Link
              title="Alterar foto"
              to={{
                // pathname: '/kjhg',
                pathname: '/',
              }}
              state={{}}
            />
            <img
              src={`${process.env.REACT_APP_AVATAR}/${user.avatar}`}
              alt={user.name}
            />
          </Photo>
        </UserInfoDiv>
      </UserInfo>
    </HeaderWrapper>
  );
}

export default Header;
