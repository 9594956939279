import styled, { css } from 'styled-components';

interface PhotoProps {
  disabled?: boolean;
}

const Banner = `${process.env.REACT_APP_IMPERIUM_ASSETS}/header_imperium.jpg`;
// `${process.env.REACT_APP_IMPERIUM_ASSETS}/Imperium_Background_1920x1080.jpg`;

export const HeaderWrapper = styled.header`
  position: relative;

  display: flex;
  /* align-items: center; */

  width: 100vw;
  height: 100px;

  /* padding-right: 20px; */

  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  transition: height 0.25s;
`;

export const UserInfo = styled.div`
  position: absolute;
  width: 50vw;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  /* background-color: red; */

  display: flex;

  justify-content: flex-end;

  padding: 0 20px 0 0;

  column-gap: 25px;
`;

export const UserInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #fff;
  p,
  strong {
    font-size: 12px;
  }
  h3 {
    margin: 10px 0 0 0;
  }
  h3 + p {
    margin-top: 5px;
  }
  &:nth-child(1) {
  }
`;

export const Photo = styled.div<PhotoProps>`
  position: relative;
  /* cursor: not-allowed; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 70px;
  min-height: 70px;
  min-width: 70px;

  border: 3px solid #fff;
  background-color: rgba(0, 84, 166, 0.75);
  border-radius: 50%;
  overflow: hidden;
  a,
  img {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: transparent;

    z-index: 10;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;

    transition: transform 0.25s;
  }

  &:hover {
    a {
    }
    img {
      transform: scale(1.15);
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
