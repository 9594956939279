import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormDateInput, FormInput, FormSelect, FormTextarea } from 'components';
import { useNavigate } from 'react-router-dom';

import api from 'services';

import { ComboProps } from 'interfaces';
import ComponentWrapper from '../wrapper';
import { CloseButton } from './styles';
import { FinishButton } from './styles';
import { lsKey } from '../../colaborator';

interface RoleProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorRole({ mode, data }: RoleProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [cbos, setCBOs] = useState<ComboProps[]>([]);
  const [bonds, setBonds] = useState<ComboProps[]>([]);
  const [personTypes, setPersonTypes] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);
  const navigate = useNavigate();

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/colaborador_funcao.php');
      setCBOs(response.data.cbo);
      setBonds(response.data.bond);
      setPersonTypes(
        response.data.person_type.filter((item) => item.value !== 'A'),
      );
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, []);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }
  }, [handleGetCombos]);

  function sairEVoltarParaHome() {
    navigate('/');
  }

  const customStyle = { width: '100%' };
  const activitiesintherole = { width: '100%', margin: '8px 0px 5px 0px' };
  const type = { width: '30%', margin: '-23px 0px 5px 5px' };
  const bond = { width: '20%', margin: '-23px 0px 5px 5px' };
  const fieldsetStyle = { width: '100%' };

  return (
    <ComponentWrapper sectionName="Função">
      <div>
        <fieldset style={fieldsetStyle}>
          <div>
            <FormSelect
              name="cbo"
              label="CBO"
              content={cbos}
              initial={prefixed.cbo || null}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />
            <FormInput
              name="role"
              placeholder="Função na empresa"
              label="Função na Empresa"
              defaultValue={prefixed.role || null}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />
            <FormInput
              name="area"
              placeholder="Área de atuação"
              label="Área de Atuação"
              defaultValue={prefixed.area || null}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />
          </div>
          <div>
            <FormTextarea
              altHeight={75}
              label="Atividades na Função"
              name="activities"
              placeholder="Informação das atividades na função"
              defaultValue={prefixed.activities || null}
              disabled={mode === 'show'}
              containerstyle={activitiesintherole}
            />
            <FormSelect
              name="bond"
              label="Vínculo"
              content={bonds}
              initial={prefixed.bond || null}
              disabled={mode === 'show'}
              containerstyle={bond}
            />
            <FormSelect
              name="person_type"
              label="Tipo"
              content={personTypes}
              initial={prefixed.person_type || null}
              containerstyle={type}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormInput
              name="register_num"
              placeholder="Registro"
              label="Registro"
              defaultValue={prefixed.register_num || null}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />

            <FormDateInput
              name="job_start"
              label="Data Entrada"
              picked={prefixed.job_start}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />

            {mode !== 'insert' ? (
              <FormDateInput
                name="job_end"
                label="Data Saída"
                picked={prefixed.job_end}
                disabled={mode === 'show'}
                containerstyle={customStyle}
              />
            ) : null}
          </div>
          <div>
            <CloseButton onClick={sairEVoltarParaHome}>
              Sair sem salvar
            </CloseButton>
            {mode !== 'show' ? (
              <FinishButton type="submit">Salvar</FinishButton>
            ) : null}
          </div>
        </fieldset>
      </div>
    </ComponentWrapper>
  );
}

ColaboradorRole.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorRole;
