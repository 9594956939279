import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import { FormInput, FormSelect, FormRadio, FormTextarea } from 'components';

import { useNavigate } from 'react-router-dom';

import api from 'services';
import { ComboProps } from 'interfaces';
import ComponentWrapper from '../wrapper';
import { CloseButton } from './styles';

import { lsKey } from '../../colaborator';

interface EducationProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

function ColaboradorEducation({ mode, data }: EducationProps) {
  const { getLocalStorage } = useLocalStorage();
  const { toggleLoading } = useLoading();
  const [educationLevels, setEducationLevels] = useState<ComboProps[]>([]);
  const [educationStatus, setEducationStatus] = useState<ComboProps[]>([]);
  const [educationPeriod, setEducationPeriod] = useState<ComboProps[]>([]);
  const [prefixed, setPrefixed] = useState<any>({} as any);
  const navigate = useNavigate();

  const handleGetCombos = useCallback(async () => {
    toggleLoading();
    try {
      const response = await api.get('/combos/colaborador_escolaridade.php');
      setEducationLevels(response.data.educationLevel);
      setEducationStatus(response.data.educationStatus);
      setEducationPeriod(response.data.educationPeriod);
    } catch (err) {
      console.error(err);
    } finally {
      toggleLoading();
    }
  }, [toggleLoading]);

  useEffect(() => {
    handleGetCombos();
    const locallyStored: any = getLocalStorage(lsKey);
    if (Object.keys(data).length > 0) {
      if (mode === 'update') {
        setPrefixed({ ...locallyStored });
      } else {
        setPrefixed({ ...data });
      }
    } else {
      if (locallyStored) {
        setPrefixed({ ...locallyStored });
      }
    }
  }, [handleGetCombos, getLocalStorage, lsKey, mode, data]);

  function sairEVoltarParaHome() {
    navigate('/');
  }

  const customStyle = { width: '100%' };
  const coursesituation = { width: '60%', margin: '8px 0px 5px 0px' };
  const classperiod = { width: '50%', margin: '0px 3px 5px 0px' };
  const fieldsetStyle = { width: '100%' };

  return (
    <ComponentWrapper sectionName="Escolaridade">
      <div>
        <fieldset style={fieldsetStyle}>
          <div>
            <FormSelect
              name="education"
              label="Nível Escolaridade"
              content={educationLevels}
              initial={prefixed.education || null}
              disabled={mode === 'show'}
              containerstyle={customStyle}
            />
            <FormRadio
              name="education_status"
              label="Situação"
              options={educationStatus}
              selected={prefixed.education_status || null}
              disabled={mode === 'show'}
              containerstyle={coursesituation}
            />
          </div>
          <div>
            <FormInput
              name="last_school"
              placeholder="Ultima (ou atual) instituição de ensino"
              label="Nome da Última (ou Atual) Instituição de Ensino"
              containerstyle={customStyle}
              defaultValue={prefixed.last_school || null}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormRadio
              name="period"
              label="Periodo das Aulas Escolares"
              options={educationPeriod}
              selected={prefixed.period || null}
              containerstyle={classperiod}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormTextarea
              altHeight={75}
              label="Curso extra"
              name="course_info"
              placeholder="Informações do curso extra"
              defaultValue={prefixed.course_info || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormRadio
              name="course"
              label="Situação do Curso Extra"
              options={educationStatus}
              selected={prefixed.course || null}
              containerstyle={classperiod}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <CloseButton onClick={sairEVoltarParaHome}>
              Sair sem salvar
            </CloseButton>
          </div>
        </fieldset>
      </div>
    </ComponentWrapper>
  );
}

ColaboradorEducation.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorEducation;
