import styled, { css } from 'styled-components';
import { shade } from 'polished';

const childButtonStyles = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 3px;

  background-color: var(--mainColor);
  border-color: var(--mainColor);

  transition: all 0.3s ease;

  svg {
    height: 17px;
    width: 17px;
    color: #efefef;
    transition: all 0.3s ease;
  }

  &:hover {
    svg {
      color: #fff;
    }
  }
`;

export const AddButton = styled.button`
  --mainColor: #1b5e20;
  ${childButtonStyles}
  &:hover {
    --mainColor: ${shade(0.1, '#1b5e20')};
  }
`;

export const UpdateButton = styled.button`
  --mainColor: #007acc;
  ${childButtonStyles}
  margin: 5px 0 0 0;
  &:hover {
    --mainColor: ${shade(0.1, '#007acc')};
  }
`;

export const RemoveButton = styled.button`
  --mainColor: #8a0002;
  ${childButtonStyles}
  margin: 5px 0 0 0;
  &:hover {
    --mainColor: ${shade(0.2, '#8a0002')};
  }
`;

export const CloseButton = styled.button`
  padding: 3px 7px;
  width: 150px;
  margin: 0 5px 5px auto;
  border: 2px solid black;
  background-color: #8B0000;
  border: 2px #B22222;
  border-radius: 5px;
  color: #efefef;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    background-color: #A52A2A;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 5px 0;
  }
`;
