import React, { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, useLoading } from 'hooks';
import {
  FormDateInput,
  FormInput,
  FormSelect,
  FormRadio,
  FormTextarea,
  Checkbox,
} from 'components';

import api from 'services';
import { ComboProps } from 'interfaces';
import ComponentWrapper from '../wrapper';

import { lsKey } from '../../assisted';

interface FamilyProps {
  mode?: 'insert' | 'update' | 'show';
  data?: any;
}

const customStyle = { width: '300px' };
const fourInRowStyle = { width: '200px' };
const fieldsetStyle = { width: '1300px' };

function ColaboradorFamily({ mode, data }: FamilyProps) {
  const [prefixed, setPrefixed] = useState<any>({} as any);

  useEffect(() => {
    // handleGetCombos();
    // const locallyStored: any = getLocalStorage(lsKey);
    // if (Object.keys(data).length > 0) {
    //   if (mode === 'update') {
    //     setPrefixed({ ...locallyStored });
    //   } else {
    //     setPrefixed({ ...data });
    //   }
    // } else {
    //   if (locallyStored) {
    //     setPrefixed({ ...locallyStored });
    //   }
    // }
  }, []);

  return (
    <ComponentWrapper sectionName="Componente Familiar">
      <div>
        <fieldset style={fieldsetStyle}>
          <legend>Dados do Cônjuge</legend>
          <div>
            <FormInput
              name="companion_name"
              placeholder="Nome"
              label="Nome"
              defaultValue={prefixed.companion_name || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_surname"
              placeholder="Sobrenome"
              label="Sobrenome"
              defaultValue={prefixed.companion_surname || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />

            <FormDateInput
              name="companion_birth"
              label="Data Nascimento"
              picked={prefixed.companion_birth}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
          <div>
            <FormInput
              name="companion_rg"
              placeholder="RG"
              label="RG"
              defaultValue={prefixed.companion_rg || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_cpf"
              placeholder="CPF"
              label="CPF"
              mask="cpf"
              defaultValue={prefixed.companion_cpf || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="companion_cin"
              placeholder="CIN"
              label="CIN"
              defaultValue={prefixed.companion_cin || null}
              containerstyle={customStyle}
              disabled={mode === 'show'}
            />
          </div>
        </fieldset>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ padding: '3px 7px', display: 'flex', columnGap: '7px' }}>
          <Checkbox
            checkboxId="conjpai"
            defaultChecked={prefixed.conjpai || false}
            // ontoggle={handleCheckboxToggle}
            // isdisabled={!state.write}
          />
          <p>
            Marcar se o cônjuge <strong>NÃO</strong> é o pai da(s) criança(s)
          </p>
        </div>
        <div>LINK PARA O PAI DAS CRIANÇAS // NOVO COMPONENTE + DIALOG</div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '90%',
          backgroundColor: 'black',
          margin: '0 auto',
          color: '#fff',
        }}
      >
        VEM UMA TABELA PARA COMPONENTES FAMILIARES
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ padding: '3px 7px', display: 'flex', columnGap: '7px' }}>
          <Checkbox
            checkboxId="refcras"
            defaultChecked={prefixed.refcras || false}
            // ontoggle={handleCheckboxToggle}
            // isdisabled={!state.write}
          />
          <p>Referenciado no CRAS</p>
        </div>
        <div style={{ padding: '3px 7px', display: 'flex', columnGap: '7px' }}>
          <Checkbox
            checkboxId="refcreas"
            defaultChecked={prefixed.refcreas || false}
            // ontoggle={handleCheckboxToggle}
            // isdisabled={!state.write}
          />
          <p>Referenciado no CREAS</p>
        </div>
        <div style={{ padding: '3px 7px', display: 'flex', columnGap: '7px' }}>
          <Checkbox
            checkboxId="vt"
            defaultChecked={prefixed.vt || false}
            // ontoggle={handleCheckboxToggle}
            // isdisabled={!state.write}
          />
          <p>Vale Transporte</p>
        </div>
        <div style={{ padding: '3px 7px', display: 'flex', columnGap: '7px' }}>
          <Checkbox
            checkboxId="cesta"
            defaultChecked={prefixed.cesta || false}
            // ontoggle={handleCheckboxToggle}
            // isdisabled={!state.write}
          />
          <p>Cesta Básica</p>
        </div>
      </div>
      <div>
        <fieldset style={fieldsetStyle}>
          <legend>Benefícios Sociais</legend>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benbol"
                  defaultChecked={prefixed.benbol || false}
                />
                <p>Bolsa Família</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benloas"
                  defaultChecked={prefixed.benloas || false}
                />
                <p>BCP/LOAS</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benalu"
                  defaultChecked={prefixed.benalu || false}
                />
                <p>Aux. Aluguel</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benpen"
                  defaultChecked={prefixed.benpen || false}
                />
                <p>Pensão</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benrec"
                  defaultChecked={prefixed.benrec || false}
                />
                <p>Aux. Reclusão</p>
              </span>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '7px',
                }}
              >
                <Checkbox
                  checkboxId="benout"
                  defaultChecked={prefixed.benout || false}
                />
                <p>Outros</p>
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <FormInput
                name="local"
                placeholder="Local"
                label="Local"
                defaultValue={prefixed.local || null}
                containerstyle={customStyle}
                disabled={mode === 'show'}
              />

              <FormInput
                name="detbenout"
                placeholder="Qual outro benefício?"
                label="Especifique"
                defaultValue={prefixed.detbenout || null}
                containerstyle={customStyle}
                disabled={mode === 'show'}
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div>
        <fieldset style={fieldsetStyle}>
          <legend>Despesas</legend>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <FormInput
              name="despalim"
              placeholder="DEVE SER NUM"
              label="Alimentação"
              defaultValue={prefixed.despalim || null}
              containerstyle={fourInRowStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="despagua"
              placeholder="DEVE SER NUM"
              label="Água"
              defaultValue={prefixed.despagua || null}
              containerstyle={fourInRowStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="despener"
              placeholder="DEVE SER NUM"
              label="Energia"
              defaultValue={prefixed.despener || null}
              containerstyle={fourInRowStyle}
              disabled={mode === 'show'}
            />
            <FormInput
              name="despout"
              placeholder="DEVE SER NUM"
              label="Outros"
              defaultValue={prefixed.despout || null}
              containerstyle={fourInRowStyle}
              disabled={mode === 'show'}
            />
          </div>
        </fieldset>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <FormInput
          name="totbenfam"
          placeholder="DEVE SER NUM"
          label="Valor Total Benefícios"
          defaultValue={prefixed.totbenfam || null}
          containerstyle={fourInRowStyle}
          disabled={mode === 'show'}
        />
        <FormInput
          name="totrendafam"
          placeholder="DEVE SER NUM"
          label="Renda total familiar"
          defaultValue={prefixed.totrendafam || null}
          containerstyle={fourInRowStyle}
          disabled={mode === 'show'}
        />
      </div>
    </ComponentWrapper>
  );
}

ColaboradorFamily.defaultProps = {
  mode: 'insert',
  data: {},
};

export default ColaboradorFamily;
