import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { FaExclamationTriangle } from 'react-icons/fa';
import { Label, CheckBoxGroupContainer, Error } from './styles';

interface OptionProps {
  value: string;
  label: string;
}

interface CustomProps {
  name: string;
  label?: string;
  selected?: string[];
  disabled?: boolean;
  options: OptionProps[];
  containerstyle?: object;
  itemsperline?: number;
}

type InputProps = JSX.IntrinsicElements['input'] & CustomProps;

function FormCheckboxGroup({
  name,
  label,
  options,
  selected,
  containerstyle,
  itemsperline,
  disabled,
}: InputProps) {
  const [defaultOption, setDefaultOption] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(() => {
    return selected || [];
  });
  const radioRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [gridline] = useState(() => {
    let lineprops = 'auto /';
    Array.from({ length: itemsperline }).forEach((item, index) => {
      lineprops += ' 1fr';
    });

    return lineprops;
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: radioRefs.current,
      getValue(refs) {
        const checked = refs.find((ref) => ref.value);
        return checked ? checked.value : null;
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  const handleClick = useCallback((ev) => {
    if (disabled) {
      return;
    }
    const { value, checked } = ev.target;

    setSelectedOptions((state) => {
      console.table(state);
      let temp = [...state];
      if (checked) {
        temp.push(value);
      } else {
        temp = temp.filter((item) => item !== value);
      }
      return temp;
    });
    setDefaultOption(value);
  }, []);

  useEffect(() => {
    if (defaultOption) {
      return;
    }
    setDefaultOption(selected);
  }, [defaultOption, selected]);

  const styled = {
    display: 'grid',
    grid: 'auto / 1fr 1fr 1fr',
  };

  const fieldsetStyle = { width: '1300px' };

  return (
    <CheckBoxGroupContainer
      error={!!error}
      style={containerstyle}
      gridprops={gridline}
    >
      <legend>{label}</legend>
      {options.map((item, index) => (
        <Label
          htmlFor={name + item.value.toString()}
          key={name + item.value.toString()}
          selected={selectedOptions.includes(item.value)}
          disabled={disabled}
        >
          <input
            ref={(elRef) => (radioRefs.current[index] = elRef)}
            type="checkbox"
            name={name}
            onClick={handleClick}
            value={item.value}
            defaultChecked={selectedOptions.includes(item.value)}
          />
          {item.label}
        </Label>
      ))}
      {error && (
        <Error title={error}>
          <FaExclamationTriangle color="#c53030" size={20} />
        </Error>
      )}
    </CheckBoxGroupContainer>
  );
}

FormCheckboxGroup.defaultProps = {
  label: null,
  selected: '',
  disabled: false,
  containerstyle: {},
  itemsperline: 5,
};

export default FormCheckboxGroup;
