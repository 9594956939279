import axios, { AxiosInstance } from 'axios';

const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    authorization: token,
    app_version: process.env.REACT_APP_VERSION,
  },
});

export default api;
