import React, { useCallback, useState } from 'react';

import { FaCheckSquare } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { CheckboxLabel } from './styles';

type CustomProps = {
  checkboxId: string;
  ontoggle?: (ev: any) => void;
  isdisabled?: boolean;
  containerStyle?: object;
};

type InputProps = JSX.IntrinsicElements['input'] & CustomProps;

function Checkbox({
  checkboxId,
  ontoggle,
  defaultChecked,
  isdisabled,
  containerStyle,
  ...rest
}: InputProps) {
  const [active, toggleActive] = useState(defaultChecked);

  const handleChanges = useCallback(
    (ev) => {
      if (isdisabled) {
        return;
      }
      toggleActive((state) => !state);
      if (ontoggle) {
        ontoggle(ev);
      }
    },
    [ontoggle],
  );

  return (
    <CheckboxLabel
      htmlFor={checkboxId}
      customchecked={active}
      isdisabled={isdisabled}
      style={containerStyle}
    >
      <input
        type="checkbox"
        data-id={checkboxId}
        id={checkboxId}
        {...rest}
        onChange={handleChanges}
      />
      <FaCheck />
    </CheckboxLabel>
  );
}

Checkbox.defaultProps = {
  ontoggle: null,
  isdisabled: false,
  containerStyle: {},
};

export default Checkbox;
