import React, { useCallback, useEffect, useState } from 'react';

import { useToast, useAuth, useLoading, useEncoder } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import * as yup from 'yup';

import { Form, FormInput, FullLoading } from 'components';

import api from 'services';

import errorHandlingUtility from 'utils/errorHandlingUtility';

import {
  Logo,
  LogoImage,
  Container,
  InfoContainer,
  Banner,
  ForgotButtonContainer,
} from './styles';

function Auth() {
  const { et64 } = useEncoder();
  const { signIn, user, updateUser } = useAuth();
  const { pathname } = useLocation();
  const { toggleLoading, loading } = useLoading();
  const { addToast, errorToast } = useToast();
  const navigate = useNavigate();

  const [token, setToken] = useState(() => {
    return pathname.includes('/reset-password')
      ? pathname.replace('/reset-password/', '')
      : null;
  });

  const admshape = {
    username: yup.string().trim().required('Campo obrigatório'),
    password: yup.string().trim().required('Senha obrigatória'),
  };
  const shape = {
    cpf: yup
      .string()
      .trim()
      .required('Campo obrigatório')
      .min(14, 'Quantidade mínima de caracteres não atingida (14)')
      .max(14, 'Quantidade máxima de caracteres excedida (14)'),
    password: yup.string().trim().required('Senha obrigatória'),
  };

  const forgotShape = {
    pass: yup
      .string()
      .required('Senha obrigatória')
      .min(6, 'No mínimo 6 dígitos'),
    passConfirm: yup
      .string()
      .oneOf([yup.ref('pass'), null], 'As senhas não são identicas'),
  };

  const handleSign = useCallback(async (data) => {
    // const curr = data.cpf.replace(/[.-]/g, '');
    const curr = data.cpf;

    signIn({ cpf: curr, pass: data.password });
  }, []);

  const handleADMSign = useCallback(async (data) => {
    signIn({ cpf: data.username, pass: data.password, who: 'adm' });
  }, []);

  const handleForgotPassword = useCallback(async (data) => {
    toggleLoading();
    try {
      const send = et64(data);

      const response = await api.post(
        '/common/email_forgot_password.php',
        send,
      );
      const { status, message } = response.data;

      if (status) {
        errorToast('err02.3', message);
        return;
      }

      addToast({
        type: 'info',
        title: 'Email enviado',
        description: 'Um email foi enviado para o endereço cadastrado.',
      });

      navigate('/signin', { replace: true });
    } catch (err) {
      errorToast('err02.4');
    } finally {
      toggleLoading();
    }
  }, []);

  const handleFirstAccess = useCallback(async (data) => {
    toggleLoading();
    try {
      const send = et64({
        p: data.pass,
        id: user.id,
      });

      const response = await api.post('/common/first_access.php', send);

      const { status, message } = response.data;

      if (status) {
        errorToast('err02.1', message);
        return;
      }

      const alterUser = {
        ...user,
        passchange: false,
      };

      updateUser(alterUser);

      addToast({
        type: 'info',
        title: 'Sucesso!',
        description: 'Sua senha foi definida com sucesso.',
      });
      setToken(null);
      navigate('/home', { replace: true });
    } catch (err) {
      errorToast('err02.2');
    } finally {
      toggleLoading();
    }
  }, []);

  const checkToken = useCallback(async () => {
    toggleLoading();
    try {
      await api.get(`/token/jwt_password.php?token=${token}`);
    } catch (err) {
      setToken(null);
      const { code } = errorHandlingUtility(err);
      let message = '';

      if (code === '404') {
        message = 'Token de reset de senha não encontrado';
      } else {
        message = 'Token de reset de senha expirado';
      }

      addToast(
        {
          type: 'error',
          title: 'Falha na requisição',
          description: message,
        },
        'password_reset_handler',
      );

      navigate('/signin', { replace: true });
    } finally {
      toggleLoading();
    }
  }, []);

  const handleResetPassword = useCallback(async (data) => {
    toggleLoading();
    try {
      const send = et64({
        pass: data.pass,
        token,
      });

      await api.post('/common/reset_password.php', send);

      localStorage.removeItem(`${process.env.REACT_APP_TOKEN}`);
      localStorage.removeItem(`${process.env.REACT_APP_USER}`);
      setToken(null);
      setTimeout(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Você já pode realizar o login com sua nova senha',
        });

        navigate('/signin', { replace: true });
      }, 500);
    } catch (err) {
      errorToast('err02.5');
    } finally {
      toggleLoading();
    }
  }, []);

  const handleChangeRoute = useCallback(() => {
    navigate('/forgot-password');
  }, []);

  useEffect(() => {
    if (pathname.includes('/reset-password')) {
      checkToken();
    }
  }, []);

  const inputStyle = { maxWidth: '200px' };
  const buttonStyle = { color: '#283593' };

  return (
    <Container>
      <FullLoading />
      <Logo>
        <span />
        <LogoImage
          alt="Logo"
          src={`${process.env.REACT_APP_IMPERIUM_ASSETS}/Imperium_Azul_Transparente_1000x1080.png`}
        />
      </Logo>

      <InfoContainer>
        <Banner>
          <h2>
            {/* {pathname.includes('signin') && 'Acesso ao sistema'} */}
            {pathname.includes('forgot-password') && 'Esqueceu sua senha?'}
            {(pathname.includes('first-access') ||
              pathname.includes('reset-password')) &&
              'Reinicie sua senha'}
          </h2>
        </Banner>
        <span />
        {pathname === '/admsignin' && (
          <Form submitHandler={handleADMSign} validationShape={admshape}>
            <FormInput
              containerstyle={inputStyle}
              name="username"
              placeholder="Usuário"
            />

            <FormInput
              containerstyle={inputStyle}
              name="password"
              type="password"
              placeholder="Senha"
              ispass
            />
          </Form>
        )}
        {pathname === '/signin' && (
          <Form submitHandler={handleSign} validationShape={shape}>
            <FormInput
              containerstyle={inputStyle}
              name="cpf"
              placeholder="CPF"
              mask="cpf"
            />

            <FormInput
              containerstyle={inputStyle}
              name="password"
              type="password"
              placeholder="Senha"
              ispass
            />
          </Form>
        )}
        {pathname.includes('forgot-password') && (
          <Form
            submitHandler={handleForgotPassword}
            validationShape={{ cpf: shape.cpf }}
          >
            <FormInput
              containerstyle={inputStyle}
              name="cpf"
              placeholder="CPF"
            />
          </Form>
        )}
        {pathname.includes('first-access') && (
          <Form submitHandler={handleFirstAccess} validationShape={forgotShape}>
            <FormInput
              containerstyle={inputStyle}
              name="pass"
              type="password"
              placeholder="Senha"
              ispass
            />
            <FormInput
              containerstyle={inputStyle}
              name="passConfirm"
              type="password"
              placeholder="Confirmar Senha"
              ispass
            />
          </Form>
        )}
        {pathname.includes('reset-password') && (
          <Form
            submitHandler={handleResetPassword}
            validationShape={forgotShape}
          >
            <FormInput
              containerstyle={inputStyle}
              name="pass"
              type="password"
              placeholder="Senha"
              ispass
            />
            <FormInput
              containerstyle={inputStyle}
              name="passConfirm"
              type="password"
              placeholder="Confirmar Senha"
              ispass
            />
          </Form>
        )}

        {pathname.includes('signin') && (
          <ForgotButtonContainer>
            <button type="button" onClick={handleChangeRoute}>
              Esqueci minha senha
            </button>
          </ForgotButtonContainer>
        )}

        <p>v{process.env.REACT_APP_VERSION}</p>
      </InfoContainer>
    </Container>
  );
}

export default Auth;
