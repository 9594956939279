import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* background-color: #accbe0; */
  background-image: linear-gradient(#accbe0, #3f80ab);
  /* background-image: linear-gradient(#accbe0, #4c90bd); */
`;

export const Content = styled.div`
  position: relative;
  &::-webkit-scrollbar {
    width: 10px;
  }
  display: flex;
  flex-direction: column;
  /* height: calc(100% - 250px); */
  height: 100%;
  /* background: red; */
  overflow-x: auto;
`;
