import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface WrapperProps {
  error?: boolean;
  gridprops: string;
}

interface LabelProps {
  selected: boolean;
  disabled: boolean;
}

export const CheckBoxGroupContainer = styled.fieldset<WrapperProps>`
  position: relative;
  display: grid !important;
  ${(props) => css`
    grid: ${props.gridprops};
  `}

  /* grid: auto / 1fr 1fr 1fr 1fr 1fr; */
  /* flex-direction: row !important;
  align-items: center;
  justify-content: center;
  column-gap: 10px; */
  gap: 10px;
  padding: 5px 10px;
  border: 2px solid #a6a6a6;
  border-radius: 5px;
  margin: 0px 5px;
  min-height: 46px;
  min-width: 330px;

  label {
  }
  > legend {
    color: #464646 !important;
    font-weight: bold;
    font-style: italic;
  }

  input {
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: #ff0000 !important;
      background: #ffebee !important;
    `}
`;

export const Label = styled.label<LabelProps>`
  --baseBackground: #fff;
  --baseBorderColor: #c6c6c6;
  --baseColor: #464646;
  position: relative;
  cursor: pointer;
  padding: 1px 7px;
  background: var(--baseBackground);
  border: 2px solid var(--baseBorderColor);
  border-radius: 3px;
  color: var(--baseColor);
  font-weight: 700;

  transition: all 0.25s linear;
  &:hover {
    --baseBackground: #e1f5fe;
    --baseBorderColor: #29b6f6;
  }

  ${(props) =>
    props.selected &&
    css`
      --baseBackground: #0d47a1;
      --baseBorderColor: #1a237e;
      --baseColor: #efefef;

      &:hover {
        --baseBackground: #1565c0;
        --baseBorderColor: #1a237e;
      }
    `}

  ${(props) =>
    props.disabled &&
    !props.selected &&
    css`
      cursor: not-allowed;
      --baseColor: #c6c6c6;

      &:hover {
        --baseBackground: #fff;
        --baseBorderColor: #c6c6c6;
      }
    `}

  ${(props) =>
    props.disabled &&
    props.selected &&
    css`
      cursor: not-allowed;

      &:hover {
        --baseBackground: #0d47a1;
        --baseBorderColor: #1a237e;
        --baseColor: #efefef;
      }
    `}
`;

export const Error = styled(Tooltip)`
  position: absolute;
  right: -25px;
  top: -25px;

  height: 20px;
  /* margin-left: 16px; */
  svg {
    margin: 0;
  }

  span {
    /* background: #c53030; */
    color: #fff;

    &::before {
      /* border-color: #c53030 transparent; */
    }
  }
`;
