import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useLocalStorage, useLoading, useToast } from 'hooks';
import { useNavigate, useLocation } from 'react-router-dom';

import { Container } from 'components';

import { Tabs, TabList, TabPanel } from 'react-tabs';
import CustomTab, { CustomTabErrorsAssisted } from 'components/CustomTab';

import 'styles/tabs.css';
// import 'react-tabs/style/react-tabs.css';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as C from '../components';
import { lsKey } from '../assisted';

function AssistedNew() {
  const { state } = useLocation();
  const { setLocalStorage, deleteLocalStorage, getLocalStorage } =
    useLocalStorage();
  const { toggleLoading } = useLoading();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const [enter, setEnter] = useState(true);
  const formRef = useRef<FormHandles>(null);

  const [currentTab, setCurrentTab] = useState(3);
  const [previousTab, setPreviousTab] = useState(0);

  const [tabsErrors, setTabErrors] = useState<CustomTabErrorsAssisted>({
    personalData: 0,
    address: 0,
    education: 0,
    family: 0,
    complement: 0,
    chidlren: 0,
    register: 0,
  });

  useEffect(() => {
    console.log('state options');
    console.table(state);
  }, []);

  const captureCheckboxGroup = useCallback((): string => {
    const asda = formRef.current.getFieldRef('liveswith');

    let retrieved = '';
    asda.forEach((element: any) => {
      if (element.checked) {
        retrieved += `${element.value},`;
      }
    });

    retrieved = retrieved.slice(0, -1);
    return retrieved;
  }, []);

  const handleCurrentTab = useCallback((tab, previous) => {
    console.clear();
    const formObject = formRef.current.getData();

    const filledProperties = {} as any;
    Object.keys(formObject).forEach((item) => {
      console.log(item);
      if (formObject[item] !== null) {
        filledProperties[item] = formObject[item];
      }

      // if (previous === 0) {
      //   filledProperties.liveswith = captureCheckboxGroup();
      // }
      if (previous === 1) {
        filledProperties.liveswith = captureCheckboxGroup();
      }
    });

    console.table(filledProperties);

    setLocalStorage(lsKey, {
      ...filledProperties,
    });

    setTimeout(() => {
      setCurrentTab(tab);
      setPreviousTab(previous);
    }, 10);
  }, []);

  const handleChanges = useCallback(() => {}, []);

  const handleSubmitUser = useCallback(() => {}, []);

  return (
    <Container screenBanner={<div>Nova Assistida</div>} hideNavbar>
      <Form
        ref={formRef}
        onSubmit={handleSubmitUser}
        style={{ margin: '0 auto' }}
        placeholder={''}
      >
        <Tabs onSelect={handleCurrentTab} selectedIndex={currentTab}>
          <TabList>
            <CustomTab errorAmount={tabsErrors.personalData}>
              Dados Pessoais
            </CustomTab>
            <CustomTab errorAmount={tabsErrors.address}>Endereço</CustomTab>
            <CustomTab errorAmount={tabsErrors.education}>
              Escolaridade
            </CustomTab>
            <CustomTab errorAmount={tabsErrors.family}>Familiar</CustomTab>
            {state.sen ? (
              <CustomTab errorAmount={tabsErrors.complement}>
                Complemento
              </CustomTab>
            ) : null}
            <CustomTab errorAmount={tabsErrors.chidlren}>Criança</CustomTab>
            <CustomTab errorAmount={tabsErrors.register}>Registro</CustomTab>
          </TabList>

          <TabPanel>
            <C.Personal onDataChange={handleChanges} />
          </TabPanel>
          <TabPanel>
            <C.Address />
          </TabPanel>
          <TabPanel>
            <C.Education />
          </TabPanel>
          <TabPanel>
            <C.Family />
          </TabPanel>
        </Tabs>
      </Form>
    </Container>
  );
}

export default AssistedNew;
